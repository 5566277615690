
<template>
    <div>
            <v-container class="my-2">
                    <v-layout row wrap px-0 mb-4>
                        <v-flex xs12>
                            <v-card class="mx-1 px-0" style="background-color:transparent!important" flat>
                                <v-card-title class="px-0">
                                    <v-breadcrumbs class="px-0" :items="$store.getters.language.data.customer_links.list_path">
                                        <template v-slot:item="{ item }">
                                            <v-breadcrumbs-item :to="item.path" :disabled="item.disabled">
                                                {{ item.title.toUpperCase() }}
                                            </v-breadcrumbs-item>
                                        </template>
                                    </v-breadcrumbs>
                                    <v-spacer></v-spacer>
                                    <!-- <v-btn class="float-right" color="primary" icon  @click="exportExcel">
                                        <v-icon>mdi-file-excel</v-icon>
                                    </v-btn> -->
                                    <v-btn class="float-right" color="primary" icon  to="/add-new-customer_links">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-card-title>
                            </v-card>
                        </v-flex>
                    </v-layout>
                <form @submit.prevent="addCustomerLinks" autocomplete="off">
                    <v-layout row wrap>
        
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="links" v-model="customer_links.link_id" dense  filled outlined  item-text="link_name"
                                    item-value="link_id" :return-object="false" :label="$store.getters.language.data.links.link_name">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md3 sm4>
                                <v-select class="mx-1" clearable  :items="customers" v-model="customer_links.customer_id" dense  filled outlined  item-text="customer_name"
                                    item-value="customer_id" :return-object="false" :label="$store.getters.language.data.customers.customer_name">
                                </v-select>
                        </v-flex>
                
                        <v-flex xs12 lg2 xl2 md2 sm4>
                            <v-btn  color="primary" :loading="loading_btn" type="submit" >{{$store.getters.language.data.customer_links.add_btn}}</v-btn>
                        </v-flex>
                    </v-layout>
                </form>
<v-layout row wrap mt-5>
    <v-flex xs12>
        <v-card flat>
            <v-card-text>
                <v-data-table :loading="loading" :headers="headers"  show-select v-model="selected_rows"    :search="search" :items="rows" class="elevation-0"
                    item-key="customer_link_id">
                    <template v-slot:[`item.customer_link_id`]="{ item }">
                        <div>
                            <v-btn icon :to="'/customer_links-list/'+item.customer_link_id"  color="teal" class="mx-1">
                                <v-icon> mdi-pencil-outline </v-icon>
                            </v-btn>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn color="error" icon class="mx-1" v-bind="attrs" v-on="on" >
                                        <v-icon> mdi-delete-outline </v-icon>
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-card-text>
                                        {{$store.getters.language.data.customer_links.delete_question}}
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn text class="elevation-0">
                                            {{$store.getters.language.data.customer_links.cancel_btn}}
                                        </v-btn>
                                        <v-btn color="error" class="elevation-0" @click="deleteCustomerLinks(item.customer_link_id)">
                                            {{$store.getters.language.data.customer_links.yes_btn}}
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                        </div>
                    </template>
                </v-data-table>
            </v-card-text>
            
            <v-card-actions v-if="selected_rows.length > 0">
                <v-btn color="error" @click="deleteCustomerLinksList">{{$store.getters.language.data.customer_links.delete_list_btn}}</v-btn>
            </v-card-actions>
                
        </v-card>
    </v-flex>
</v-layout>
</v-container>

<v-snackbar v-model="snackbar.value" :color="snackbar.color">
    {{snackbar.text}}
</v-snackbar>
</div>
</template>
<script>
    import requests from './../../requests/customer_links.request.js'
    import * as xlsx from 'xlsx/xlsx.js';
    
    export default {
        
        data() {
            return {
                customer_links: {},
                search: '',
                loading : true,
                loading_btn:false,
                selected_rows : [],
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                rows:[],
                links : [],customers : [],
                selected_customer_links : {},
                headers: [

                    
                    {
                        text: this.$store.getters.language.data.links.link_name,
                        align: 'start',
                        sortable: true,
                        value: 'link_id',
                    },
                    {
                        text: this.$store.getters.language.data.customers.customer_name,
                        align: 'start',
                        sortable: true,
                        value: 'customer_id',
                    },   {
            text: this.$store.getters.language.data.actions,
            align: 'start',
            sortable: true,
            value: 'customer_link_id',
        }
                ],
            }
        },
        computed: {
            page(){
                const pageKey = 'CUSTOMERLINKS'
                return {}
            }
        },
        mounted(){
            this.readCustomerLinks();
        },
        methods: {
            exportExcel(){
                const list = this.rows
                let result = []
                for (let i = 0; i < list.length; i++) {
                    const item = list[i];
                    let obj = {customer_link_id : item.customer_link_id,link_id : item.link_id,customer_id : item.customer_id,
                    }
                    result.push(obj)
                }
				var f = xlsx.utils.json_to_sheet(result)
				var wb = xlsx.utils.book_new()
				xlsx.utils.book_append_sheet(wb, f, 'sheet')
				xlsx.writeFile(wb, result.length+' rows of '+'CustomerLinks.xlsx')
            },
            addCustomerLinks() {
                this.loading_btn = true
                requests.createCustomerLinks(this.customer_links).then(r => {
                    if (r.status == 200) {
                        this.customer_links = {}
                        this.rows.push(
                            r.data.new_data
                        )
                        this.snackbar = {
                            value: true,
                            text: 'CustomerLinks Added',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to add CustomerLinks',
                            color: 'error'
                        }
                    }
                })
                .finally(() => {
                    this.loading_btn = false
                })

            },
            deleteCustomerLinks(customer_link_id) {
                requests.deleteCustomerLinks(customer_link_id).then(r => {
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{
                            return f.customer_link_id != customer_link_id
                        })
                        this.snackbar = {
                            value: true,
                            text: 'CustomerLinks Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            
            deleteCustomerLinksList() {
                let ids = this.selected_rows.map(m => m.customer_link_id)
                requests.deleteCustomerLinksList(ids).then(r => {
                    if (r.status == 200) {
                        this.rows = this.rows.filter(f =>{  
                            return ids.indexOf(f.customer_link_id) == -1
                        })
                        this.snackbar = {
                            value: true,
                            text: this.selected_rows.length+' CustomerLinks Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            
            readCustomerLinks() {
                this.loading = true
                requests.getAllCustomerLinks().then(r => {
                    if (r.status == 200) {
                        this.rows = r.data.customer_links
                        
            this.links = r.data.links
            
            this.customers = r.data.customers
            
                        this.loading = false
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to read CustomerLinks',
                            color: 'error'
                        }
                    }
                })
                .catch(e => {
                    this.snackbar = {
                        value: true,
                        text: 'Fail to read CustomerLinks',
                        color: 'error'
                    }
                })
                .finally(() => {
                    this.loading = false
                })
            },
            selectCustomerLinks(i) {
                this.selected_customer_links = i
                this.delete_dialog = true
            },
        },
    }
</script>
                    