
import axios from 'axios';
export default {
    
    async createCustomerLinks(params)  {
        return await axios.post(`customer_links/create` , params)
    },
    async createCustomerLinksList(params)  {
        return await axios.post(`customer_links/create/list` , params)
    },
    async updateCustomerLinksColumn(column , value , data)  {
        return await axios.put(`customer_links/update_list?${column}=${value}` , data)
    },
    async deleteCustomerLinksList(list)  {
        return await axios.delete(`customer_links/delete_list` , {
            data: {
                list:list
            }
        })
    },
    async reportCustomerLinks(column , value)  {
        return await axios.get(`customer_links/report?${column}=${value}`)
    },
    async getAllCustomerLinks()  {
        return await axios.get(`customer_links/all`)
    },
    async getOneCustomerLinks(customer_link_id)  {
        return await axios.get(`customer_links/all/${customer_link_id}`)
    },
    async getCustomerLinksByColumn(column , value)  {
        return await axios.get(`customer_links/filter?column=${column}&value=${value}`)
    },
    async deleteCustomerLinks(customer_link_id)  {
        return await axios.delete(`customer_links/delete/${customer_link_id}`)
    },
    async updateCustomerLinks(customer_link_id , params)  {
        return await axios.put(`customer_links/update/${customer_link_id}` , params)
    }
}